import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import Currency from './currency';

import Payment from './payment';

import Shipping from './shipping';

import LoadingIndicator from './../LoadingIndicator';

export default class Detail extends Component {

  componentWillMount() {

    if (!this.props.customer.signed_in) {
      window.location = '/customers/sign_in'; // Redirect to login
    }

  }

  render() {

    const {
      loading, toggleEdit, cart, cart: { items, total, sub_total },
      changePayment, changeShipping, customer, payments
    } = this.props;

    if (!customer.signed_in) {
      return <LoadingIndicator />;
    }

    return (
      <Fragment>
        <div className="Application__box">
          <div className="Application__title Application__title--flex">
            <span>IL MIO CARRELLO</span>
            <span>
              <div className="Cart__editButton" onClick={toggleEdit}>Modifica</div>
            </span>
          </div>
          <div className="Application__separator"></div>
          {items.map(item => (
            <div className="Cart__smallItem" key={item.id}>
              <span>
                {item.name}
              </span>
              <span>
                <Currency value={item.total} />
              </span>
            </div>
          ))}
          <div className="Cart__subtotal" style={{ marginBottom: 0 }}>
            <span>Subtotale</span>
            <span>
              <Currency value={sub_total} />
            </span>
          </div>
        </div>

        <div className="Cart__boxHalf">

          <Shipping {...{ cart, customer }} onChange={changeShipping} />

          <Payment {...{ cart, payments }} onChange={changePayment} />

        </div>

        <div className="Application__padding">
          <div className="Cart__smallItem" style={{ margin: "10px 0 " }}>
            <span>Spedizione</span>
            <span>
              <Currency value={cart.delivery_price} />
            </span>
          </div>
          <div className="Cart__subtotal">
            <span>Totale:</span>
            <span>
              <Currency value={total} />
            </span>
          </div>

          <a href="/carrello/conferma" className={cx("btn", { 'btn--disabled': loading })} style={{ marginBottom: 40 }}>
            Concludi l'acquisto
          </a>

        </div>

      </Fragment>
    );

  }

}
