import React, { useEffect, useState } from 'react';

const truncate = function(str) {
    return str.length > 10 ? str.substring(0, 7) + "..." : str;
}

export default function Reviews() {

  const [reviews, setReviews] = useState([])

  useEffect(() => {
    fetch(`/recensioni`)
      .then((response) => response.json())
      .then((actualData) => setReviews(actualData));
  }, []);

  return (
    <>
      <div className='container'>
        <ul className='cards'>
          {
            reviews.map(r => {
              return (
                <li className='card'>
                  <div className='card-content'>
                    <div className="left" style={{ float: 'left', paddingRight: 10 }}>
                      <div className='image-container'>
                        <img src={`${r.profile_photo_url}`} width="40" />
                      </div>
                    </div>
                    <div className="right" style={{ float: 'right' }}>
                      <h3>
                        {r.author_name}
                      </h3>
                      <p>{`"${truncate(r.text)}"`}</p>
                    </div>
                    <div className='google-container'>
                      <div className='ratings'>
                        {Array.from({ length: r.rating }).map((_, i) => (
                          <img className='star' src={ require(`../../../assets/images/svg/homepage/star`) }></img>
                        ))}
                      </div>
                      <img className='google-logo' src={ require(`../../../assets/images/svg/homepage/google`) }></img>
                    </div>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    </>
  )
}
