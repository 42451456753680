import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Counter from 'react-countdown-now';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return (
      <div>You are good to go!</div>
    );
  } else {
    return (
      <Fragment>
        <div>
          <div className="Countdown__digit">{days}</div>
          <div className="Countdown__text">giorni</div>
        </div>
        <div>
          <div className="Countdown__digit">{hours}</div>
          <div className="Countdown__text">ore</div>
        </div>
        <div>
          <div className="Countdown__digit">{minutes}</div>
          <div className="Countdown__text">minuti</div>
        </div>
        <div>
          <div className="Countdown__digit">{seconds}</div>
          <div className="Countdown__text">secondi</div>
        </div>
      </Fragment>
    );
  }
};


class Countdown extends React.Component {
  render () {
    return (
      <div className="Countdown">
        <Counter
          date={this.props.date}
          renderer={renderer}
        />
      </div>
    );
  }
}

Countdown.propTypes = {
  date: PropTypes.node
};
export default Countdown
