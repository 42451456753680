import React from 'react';

import Result from './Result';
import EmptyResult from './EmptyResult';

import { WithLoading } from './../../LoadingIndicator';

export default class ResultsContainer extends React.Component {

  render(){

    const { data, loading } = this.props;

    const items = data.map((item) => (
      <Result key={item.id} {...item} />
    ));

    return (
      <div className="InstantSearch__results">

        <ul>
          <WithLoading isLoading={loading}>
            {items.length === 0 ? <EmptyResult /> : items}
          </WithLoading>
        </ul>

      </div>
    );

  }

}
