import React, { Component } from "react";
import PropTypes from "prop-types";
import assign from "object-assign";
import cx from "classnames";

class IconWrapper extends Component {

  getIconWrapperDimensions = (height, width) => {

    if (height && width) {
      return { height, width };
    }

    if (!height && width) {
      return { height: width, width };
    }

    if (height && !width) {
      return { height, width: height };
    }

    return {
      height: 30,
      width: 30,
    };
  }

  render() {
    const { children, className, fill, height, label, viewBox, width } = this.props;

    const dimensions = this.getIconWrapperDimensions(height, width);

    const style = {
      display: "inline-block",
      fill: fill || null,
      lineHeight: 1,
      verticalAlign: "middle",
      height: height || dimensions.height,
      width: width || dimensions.width,
    };

    return (
      <svg
        className={cx("IconWrapper", className)}
        style={this.props && this.props.style ? assign({}, style, this.props.style) : style}
        viewBox={viewBox}
      >
        {children}
      </svg>
    );
  }
}

IconWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  viewBox: PropTypes.string.isRequired,
  className: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  style: PropTypes.object,
};

IconWrapper.defaultProps = {
  viewBox: "0 0 50 50",
  style: {},
};

const Icon = {};

Icon.Lens = props => (
  <IconWrapper {...props}>
    <path d="M48.7,46.8L34.4,32.5c3.3-3.6,5.2-8.3,5.2-13.2c0-5.2-2-10.1-5.7-13.7C30.2,2,25.4,0,20.2,0
      C15,0,10.1,2,6.5,5.7s-5.7,8.5-5.7,13.7c0,10.6,8.7,19.3,19.4,19.3c4.2,0,8-1.3,11.4-3.7l14.4,14.4c0.4,0.4,0.9,0.6,1.4,0.6
      s1-0.2,1.4-0.6C49.4,48.7,49.4,47.5,48.7,46.8z M30.4,31.1L30.4,31.1c-2.8,2.4-6.3,3.8-10.2,3.8c-8.6,0-15.6-7-15.6-15.5
      c0-4.2,1.6-8,4.5-11s6.8-4.6,11-4.6s8,1.6,11,4.5s4.6,6.8,4.6,11C35.8,24,33.7,28.2,30.4,31.1z M24.8,9c-3.7-1.5-7.8-1.1-11.1,1
      c-2.6,1.7-4.4,4.4-5,7.5c-0.6,3.1,0,6.2,1.7,8.8c0.4,0.6,0.9,0.9,1.6,0.9c0.4,0,0.7-0.1,1.1-0.3c0.9-0.6,1.1-1.7,0.5-2.7
      c-2.4-3.7-1.4-8.6,2.2-11.1c2.2-1.5,5-1.7,7.5-0.7c0.9,0.4,2.1-0.1,2.5-1C26.2,10.5,25.8,9.4,24.8,9z"/>
  </IconWrapper>
);

Icon.Close = props => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M17.82,16l8.6-8.33a1.26,1.26,0,0,0-1.76-1.82L16,14.24,7.34,5.86a1.31,1.31,0,0,0-.9-.36,1.26,1.26,0,0,0-.86,2.17L14.18,16l-8.6,8.33a1.26,1.26,0,0,0,0,1.79,1.3,1.3,0,0,0,1.79,0L16,17.76l8.66,8.39a1.26,1.26,0,0,0,1.76-1.82Z" />
  </IconWrapper>
);

Icon.Remove = props => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M17.82,16l8.6-8.33a1.26,1.26,0,0,0-1.76-1.82L16,14.24,7.34,5.86a1.31,1.31,0,0,0-.9-.36,1.26,1.26,0,0,0-.86,2.17L14.18,16l-8.6,8.33a1.26,1.26,0,0,0,0,1.79,1.3,1.3,0,0,0,1.79,0L16,17.76l8.66,8.39a1.26,1.26,0,0,0,1.76-1.82Z" />
  </IconWrapper>
);

Icon.Avatar = props => (
  <IconWrapper {...props}>
    <path d="M25,28c7,0,12.8-5.7,12.8-12.8c0-7-5.8-12.7-12.8-12.7S12.2,8.2,12.2,15.3C12.2,22.3,18,28,25,28z M25,6
      c5.1,0,9.3,4.2,9.3,9.3s-4.2,9.2-9.3,9.2s-9.3-4.1-9.3-9.2S19.9,6,25,6z M50,45.5c-1.4-11.3-6.5-17.1-15.6-17.7
      c-0.4,0-0.9,0.1-1.2,0.3c-4.8,3.7-11.6,3.7-16.4,0c-0.3-0.3-0.7-0.4-1.2-0.3C6.4,28.4,1.3,34.2,0,45.5c-0.1,0.5,0.1,1,0.4,1.4
      c0.3,0.4,0.8,0.6,1.3,0.6h46.5c0.5,0,1-0.2,1.3-0.6C49.9,46.5,50,46,50,45.5z M3.7,44c1.4-8,5-12,11.5-12.6c5.8,4.1,13.7,4.1,19.5,0
      C41.3,32,44.9,36,46.3,44H3.7z"/>
  </IconWrapper>
);

Icon.Cart = props => (
  <IconWrapper {...props}>
    <path d="M26,35.1c-2.8,0-5,2.3-5,5s2.3,5,5,5s5-2.3,5-5S28.8,35.1,26,35.1z M26,42.1c-1.1,0-2-0.9-2-2s0.9-2,2-2
      c1.1,0,2,0.9,2,2S27.1,42.1,26,42.1z M48.4,4.9h-8.6c-0.7,0-1.3,0.4-1.5,1.1l-7.6,23.4H8.9l-5.2-14h27c0.9,0,1.5-0.7,1.5-1.5
      c0-0.9-0.7-1.5-1.5-1.5H1.5c-0.5,0-1,0.3-1.3,0.7s-0.4,0.9-0.2,1.4l6.3,17.1c0.2,0.6,0.8,1,1.4,1h24c0.7,0,1.3-0.4,1.5-1.1L41,7.9
      h7.5c0.9,0,1.5-0.7,1.5-1.5S49.3,4.9,48.4,4.9z M13.4,35.1c-2.8,0-5,2.3-5,5s2.3,5,5,5s5-2.3,5-5S16.1,35.1,13.4,35.1z M13.4,42.1
      c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S14.5,42.1,13.4,42.1z"/>
  </IconWrapper>
);

export default Icon
