import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import withApollo from "withApollo";
import Icon from 'components/Icon';
import ToggleSwitch from 'components/ToggleSwitch';

import _addToFavorite from "graphql/mutations/addToFavorite.gql";

import './styles.scss';

function AddToFavorite(props) {

  const [scope, setScope] = useState(props.favorited)

  const [addToFavorite, { loading: adding }] = useMutation(_addToFavorite, {
    onCompleted: (data) => {
      setScope(data.addToFavorite);
    }
  });

  const addToFavoriteButton = (scope) => {

    if (props.signed_in) {
      addToFavorite({ variables: { productId: props.id, scope } })
    } else {
      window.location.href = '/customers/sign_in'
    }

  }

  const { discounted, pre_owned, pre_order, available } = props;

  return (
    <div className="AddToFavorite">
      <div>
        Aggiungi questo prodotto ai tuoi preferiti
        <ToggleSwitch
          onToggle={() => addToFavoriteButton('favorite')}
          checked={scope.favorite}
          data-test="add-to-favorite"
          disabled={adding}
        />
      </div>
      {!pre_order && !pre_owned && (
        <div>
          Ricevi una notifica quando il prodotto &egrave; disponibile usato
          <ToggleSwitch
            onToggle={() => addToFavoriteButton('pre_owned')}
            checked={scope.preOwned}
            data-test="add-to-favorite"
            disabled={adding}
          />
        </div>
      )}
      {pre_order && (
        <div>
          Ricevi una notifica quando il prodotto &egrave; disponibile
          <ToggleSwitch
            onToggle={() => addToFavoriteButton('pre_order')}
            checked={scope.preOrder}
            data-test="add-to-favorite"
            disabled={adding}
          />
        </div>
      )}
      {!discounted && (
        <div>
          Ricevi una notifica quando il prodotto diventa scontato
          <ToggleSwitch
            onToggle={() => addToFavoriteButton('discounted')}
            checked={scope.discounted}
            data-test="add-to-favorite"
            disabled={adding}
          />
        </div>
      )}
    </div>
  );
}

export default withApollo(AddToFavorite);
