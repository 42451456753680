import React, { Component, Fragment } from 'react';

const Empty = ({ customer: { signed_in }}) => (
  <div className="Application__padding">

    <div className="Application__title">Il tuo carrello &egrave; vuoto</div>

    <p className="text-center" style={{ margin: 40 }}>
      Spediamo gli articoli in tutta Italia o, se preferisci, &egrave; possibile pagare direttamente al ritiro in negozio.
    </p>

    {!signed_in && (
      <Fragment>
        <a href="/customers/sign_in" className="btn btn--margin">Accedi</a>
        <a href="/customers/sign_up" className="btn btn--outline">Registrati</a>
      </Fragment>
    )}

  </div>
);

export default Empty;
