import React, { Component, Fragment } from 'react';

import Select from 'react-select';

import Currency from './currency';

export default class Shipping extends Component {

  handleChange = ({ label, value }) => {

    this.props.onChange(true, value);

  }

  render() {

    const { customer, onChange, cart: { sub_total, delivery_price, deliveries, delivery_id, delivery_category, pickup_available } } = this.props;

    const current_delivery = deliveries.find(d => d.id === delivery_id) || {};

    return (
      <div className="Application__box Application__box--shipping">

        <div className="Application__title Application__title--flex">SPEDIZIONE O RITIRO?</div>
        <div className="Application__subtitle">Scegli il sistema pi&ugrave; consono alle tue esigenze.</div>
        <div className="Application__separator"></div>

        <div className="d-flex justify-content-between align-items-center">

          <div className="radio" onClick={() => {if (customer.has_shipping_address) { onChange('shipping') }}}>
            <input type="radio" name="delivery_category" value="shipping" checked={delivery_category == "shipping"} disabled={!customer.has_shipping_address} />
            <label className="radio-label" htmlFor="cart_delivery_category_shipping">Spedizione a Domicilio</label>
          </div>

          {!customer.has_shipping_address && (
            <div className="Cart__shippingAdd">
              <a href="/carrello/spedizione">Aggiungi Indirizzo</a>
            </div>
          )}

        </div>

        {customer.has_shipping_address && (
          <div className="Cart__shipping">
            <div className="Application__subtitle">Indirizzo di spedizione</div>
            <div className="Cart__address">
              <div dangerouslySetInnerHTML={{ __html: customer.ship_address }} />
              <a href="/carrello/spedizione" className="Cart__editButton">Modifica</a>
            </div>
          </div>
        )}

        {customer.has_shipping_address && delivery_category == "shipping" && (
          <Select
            value={{ label: current_delivery.name, value: current_delivery.id }}
            onChange={this.handleChange}
            options={deliveries.map(s => ({ label: s.name, value: s.id }))}
          />
        )}

        {sub_total < 100 && (
          <div className="Cart__shippingFee">
            Spendi ancora <Currency value={100 - sub_total} /> per avere la spedizione gratuita
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center mt-4">

          <div className="radio" onClick={() => {if (customer.has_shipping_address && pickup_available) { onChange('delivery') }}}>
            <input type="radio" name="delivery_category" value="delivery" checked={delivery_category == "delivery"} disabled={!customer.has_shipping_address || !pickup_available}/>
            <label className="radio-label" htmlFor="cart_delivery_category_delivery">Speedy Pack</label>
          </div>

          {!customer.has_shipping_address && (
            <div className="Cart__shippingAdd">
              <a href="/carrello/spedizione">Aggiungi Indirizzo</a>
            </div>
          )}

        </div>

        <div className="Application__subtitle mt-2">
          Speedy Pack rappresenta l'ultima frontiera delle spedizioni veloci. Per tutti gli ordini ricevuti entro le 16.00 della giornata in corso, ci sarà la possibilità di avere la merce direttamente a casa vostra nella serata stessa tra le 18:00 e le 19:30.
          <br/>
          Questo servizio è attivo solo per determinati CAP che rientrano in un raggio di azione di 10km.
          <br/>
          Il servizio è gratuito con ordini superiori a 50€
        </div>

        <div className="mt-4">
          <div className="radio" onClick={() => onChange('pickup')}>
            <input type="radio" name="delivery_category" value="pickup" checked={delivery_category == "pickup"} />
            <label className="radio-label" htmlFor="cart_delivery_category_pickup">Ritiro in Negozio</label>
          </div>

          <div className="Cart__shipping">
            <div className="Application__subtitle">Indirizzo</div>
            <div className="Cart__address">
              <div>Quintadimensione</div>
              <div>Via B. Melzi 41</div>
              <div>20138 Legnano (MI)</div>
              <div>Italia</div>
            </div>
          </div>
        </div>

        <div className="Application__subtitle" style={{ position: 'absolute', bottom: 0 }}>
          Per maggiori informazioni vedi <a href="/spedizioni">SPEDIZIONI</a>
        </div>

      </div>
    );
  }
};
