import React, { Component, Fragment } from 'react';
import axios from 'axios';
import update from 'immutability-helper';

import Empty from './empty';
import Detail from './detail';
import Edit from './edit';

export default class Cart extends Component {

  constructor(props) {
    super(props);

    const { payments, cart, editing = true } = props.data

    this.state = {
      cart,
      editing,
      payments,
      loading: false,
    }

  }

  toggleEdit = () => this.setState((prevState) => ({ editing: !prevState.editing }))

  currentPayment = () => this.props.data.payments.find(p => p.id === this.state.cart.payment_id) || { price: 0 }

  currentShipping = () => this.state.cart.deliveries.find(p => p.id === this.state.cart.delivery_id) || { price: 0 }

  updateItem = (id, val) => {

    const newState = update(this.state, { cart: { items: arr => arr.map(item => {

      if (item.id === id) { item.quantity = val.value }

      return item

    })}});

    this.setState(newState, this.persistCart);

  }

  deleteItem = (id) => {

    const newState = update(this.state, { cart: { items: arr => arr.filter(item => item.id !== id) }});

    this.setState(newState, () => {

      this.setState({ loading: true }, () => {

        axios.delete(`/carrello/elimina/${id}`).then(res => {

          this.setState({ loading: false, cart: res.data.cart })

        });

      });

    });

  }

  changeShipping = (delivery_category, id) => {
    this.setState(update(this.state, { cart: { delivery_category: { $set: delivery_category }, delivery_id: { $set: id } } }), this.persistCart)
  }

  changePayment = (id) => {
    this.setState(update(this.state, { cart: { payment_id: { $set: id } } }), this.persistCart)
  }

  persistCart = () => {

      const { cart: { delivery_category, delivery_id, payment_id, items } } = this.state;

      this.setState({ loading: true }, () => {

      axios.put('/carrello/aggiorna', {
        cart: {
          delivery_id,
          payment_id,
          delivery_category,
          items_attributes: items.map(({ id, quantity }) => ({ id, quantity })),
        }
      }).then(res => {

        this.setState({ loading: false, cart: res.data.cart, payments: res.data.payments })

      });

      // Gestione errore chiamata

    });

  }

  render() {

    const { customer } = this.props.data;

    const { payments } = this.state;

    const detail = <Detail
      {...{ payments, customer }}
      changePayment={this.changePayment}
      changeShipping={this.changeShipping}
      loading={this.state.loading}
      cart={this.state.cart}
      toggleEdit={this.toggleEdit}
    />

    const edit = <Edit
      cart={this.state.cart}
      loading={this.state.loading}
      toggleEdit={this.toggleEdit}
      updateItem={this.updateItem}
      deleteItem={this.deleteItem}
    />;

    const empty = <Empty
      customer={customer}
    />;

    return (

      <div className="Cart">

        {this.state.cart.items.length > 0 ? (this.state.editing ? edit : detail) : empty}

      </div>
    );

  }

}
