import React from 'react';
import cx from 'classnames';

import ResultsContainer from './ResultsContainer';
import Icon from './../Icon';

export default class Input extends React.Component {

  render() {

    const hasQuery = this.props.query.trim().length > 0

    const results = hasQuery && <ResultsContainer {...this.props} />;

    return (
      <div className={cx("InstantSearch__input")}>

        <div className={cx("InstantSearch__iconSearch", { "InstantSearch__iconSearch--focus": hasQuery })} onClick={(e) => this.search(e, true)}>
          <Icon.Lens width={20} height={20} />
        </div>

        <input
          onChange={this.props.change}
          onPaste={this.props.change}
          onKeyPress={this.search}
          placeholder={this.props.placeholder}
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          id="searchInput"
          type="search"
          role="combobox"
         />

        {results}

      </div>
    );

  }

  search = (event, submit) => {

    const { query } = this.props;

    if (event.key == 'Enter' || submit) {
      window.location.href = `/prodotti?query=${encodeURIComponent(query)}`
    }

  }

}
