import React from "react"

export default function SliderCard({ name, image, slug }) {

  return (
    <div className="number-slide Homepage__box">
      <a href={`/prodotto/${slug}`}>
        <div className="ProductDetail__image">
          {image && <img src={image} />}
        </div>
        <div className="ProductDetail__title">
          {name}
        </div>
      </a>
    </div>
  );
}
