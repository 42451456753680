import React, { Component, Fragment } from 'react';

import Barcode from 'react-barcode';

export default class BarcodeComponent extends Component {

  state = {
    showCode: false
  }

  render() {

    const { showCode } = this.state;

    return (
      <div>

        {!showCode && <div className="Customer__card" onClick={() => this.setState({ showCode: true })}>Visualizza Tessera</div>}

        {showCode && (
          <div style={{ margin: '10px 0' }}>
            <Barcode
              value={this.props.value}
              format="CODE39"
              height={40}
              fontSize={10}
              displayValue={false}
            />
            <div style={{ color: '#FFF', fontSize: 12 }}>{this.props.value}</div>
          </div>
          )}

      </div>
    );
  }

}

