import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import bemClassName from "bem-classname";

import { uncontrollable } from "uncontrollable";

import './index.scss';

class ToggleSwitch extends PureComponent {
  className = bemClassName.bind(null, "ToggleSwitch");

  componentDidMount() {
    const { autoFocus, disabled } = this.props;
    if (autoFocus && !disabled) {
      this.node.focus();
    }
  }

  handleKeyDown = e => {
    e.preventDefault();
    if (e.keyCode === 37) {
      // Left
      this.props.onToggle(false);
    } else if (e.keyCode === 39) {
      // Right
      this.props.onToggle(true);
    } else if (e.keyCode === 32 || e.keyCode === 13) {
      // Space, Enter
      this.props.onToggle(!this.props.checked);
    }
  };

  //// Handle auto focus when click switch in Chrome
  handleMouseUp = e => {
    this.props.preventFocus && this.node.blur();
  };

  render() {
    const {
      className,
      props: {
        disabled,
        tabIndex,
        checkedDescription,
        uncheckedDescription,
        name,
        descriptionPosition,
        insideLabel,
        checked,
      },
    } = this;

    const switchTabIndex = disabled ? -1 : tabIndex || 0;

    return (
      <div
        className={className({ disabled, [descriptionPosition]: true })}
        onKeyDown={this.handleKeyDown}
        onClick={e => !disabled && this.props.onToggle(!checked)}
        onMouseUp={this.handleMouseUp}
      >
        <div
          className={className(insideLabel ? "containerInsideLabel" : "container")}
          tabIndex={switchTabIndex}
          ref={node => (this.node = node)}
        >
          <input type="checkbox" name={name} checked={checked} readOnly />
          <span className={className("switch")}>{insideLabel}</span>
          <span className={className("toggle")} />
        </div>
        <label>{checked ? checkedDescription : uncheckedDescription}</label>
      </div>
    );
  }
}

ToggleSwitch.propTypes = {
  /* Etichetta su campo attivato **/
  checkedDescription: PropTypes.string,
  /* Etichetta su campo disattivato **/
  uncheckedDescription: PropTypes.string,
  /* Posizione della descrizione **/
  descriptionPosition: PropTypes.string,
  /* Etichetta interna al toggle **/
  insideLabel: PropTypes.string,
  /* Disabilitato **/
  disabled: PropTypes.bool,
  /* Evento su cambio valore **/
  onToggle: PropTypes.func,
  /* **/
  tabIndex: PropTypes.number,
  /* Abilitato (controlled) **/
  checked: PropTypes.bool,
  /* Abilitato di default **/
  defaultChecked: PropTypes.bool,
  /* AutoFocus sul mount **/
  autoFocus: PropTypes.bool,
  /* Impedisci di mantenere il focus **/
  preventFocus: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
  defaultChecked: false,
  descriptionPosition: "right",
  preventFocus: false,
};

export { ToggleSwitch };

const UncontrolledToggleSwitch = uncontrollable(ToggleSwitch, {
  checked: "onToggle",
});

export default UncontrolledToggleSwitch;
