import React from 'react';

export default class EmptyResult extends React.Component {

  render() {

    return (
      <div className="InstantSearch__noResults">
        Nessun risultato
      </div>
    );

  }

}
