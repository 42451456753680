import React from 'react';
import ReactDOM from 'react-dom';

import Icon from './../Icon';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

const getSuggestionValue = suggestion => suggestion.name;

function renderSuggestion(suggestion, { query }) {
  const matches = AutosuggestHighlightMatch(suggestion.name, query);
  const parts = AutosuggestHighlightParse(suggestion.name, matches);

  return (
    <span>
      {parts.map((part, index) => {
        const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;

        return (
          <span className={className} key={index}>
            {part.text}
          </span>
        );
      })}
    </span>
  );
}

const renderSuggestionsContainer = ({ containerProps, children, query }) => (
  <div {...containerProps}>
    {
      <div className="footer">
        Premi Invio per cercare <strong>{query}</strong>
      </div>
    }
    {children}
  </div>
);

function onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {

  window.location.href = `/prodotti?query=${encodeURIComponent(suggestionValue)}`;

}

export default class Search extends React.Component {

  constructor() {
    super();

    this.state = {
      value: '',
      data: [],
      isLoading: false,
    };

  }

  componentDidMount() {

    this.createContainer();

  }

  createContainer = () => {

    let container = document.getElementById("OverlayContainer");

    if (!container) {
      let opc = document.createElement("div");

      opc.id = "OverlayContainer";

      container = document.getElementsByTagName("body")[0].appendChild(opc);
    }

    return container;

  };

  loadSuggestions(query) {

    this.props.index.search(query, {
      limit: 10,
      offset: 0
    })
      .then(({ hits }) => {
        this.setState({
          loading: false,
          data: hits,
        });
      })
      .catch(err => {
        console.error(err);
      });

  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  onFocus = () => {
    document.body.classList.add("noScroll");
  };

  onBlur = () => {
    document.body.classList.remove("noScroll");
  };

  onKeyDown = (event, val) => {
    if (event.key === 'Enter') {
      window.location.href = `/prodotti?query=${encodeURIComponent(this.state.value)}`;
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      data: []
    });
  };

  render() {

    const { value, data, isLoading } = this.state;

    const inputProps = {
      placeholder: 'Cerca videogiochi, boardgames, action figure',
      value,
      onChange: this.onChange,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onKeyDown: this.onKeyDown,
    };

    const status = (isLoading ? 'Loading...' : 'Type to load suggestions');

    const renderInputComponent = inputProps => (
      <div className="inputContainer">
        <input {...inputProps} />
        <Icon.Lens width={20} />
      </div>
    );

    return (
      <Autosuggest
        suggestions={data}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
      />
    );
  }
}
