import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    'panel', 'overlay', 'close',
    'cartItems', 'cartAddButton',
  ]

  toggleMenu(event) {

    event.preventDefault();

    this.panelTarget.classList.toggle('open');

    this.overlayTarget.classList.toggle('open');

    this.closeTarget.classList.toggle('open');

    document.body.classList.toggle('noScroll');

  }

  cartAddSuccess(event) {

    let [data, status, xhr] = event.detail;

    this.cartItemsTarget.innerHTML = data.cart.items.length;

    this.cartAddButtonTargets.forEach((elem) => {

      if (data.id === elem.dataset.id) {
        elem.innerHTML = 'PRODOTTO NEL CARRELLO';
        elem.classList.add('btn--disabled');
      }

    });

  }

}
