import React from 'react';
import Gallery from 'react-image-gallery';

export default class ImageGallery extends React.Component {

  render() {
    return (
      <Gallery
        items={this.props.images}
        lazyLoad
        showThumbnails={false}
        showPlayButton={false}
        useBrowserFullscreen={false}
      />
    );
  }

}
