import React from 'react';
import format from 'date-fns/format'
import itLocale from 'date-fns/locale/it'

export default class ResearchResult extends React.Component {

  render() {

    const { name, count } = this.props;

    return (
      <li onClick={this.onClick}>
        <div>{name}</div>
        <span>{count}</span>
      </li>
    );

  }

  onClick = () => {
    window.location.href = `/prodotti?query=${this.props.name}`;
  }

}
