import React, { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "../../../assets/stylesheets/slider.scss"
import SliderCard from './Card';

export default function Slider ({ cards, slugs, title, id, icon }) {

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    breakpoints: {
      '(max-width: 480px)': {
        slides: {
          perView: 1,
        },
      },
      '(min-width: 480px) and (max-width: 780px)': {
        slides: {
          perView: 2,
        },
      },
      '(min-width: 780px)': {
        slides: {
          perView: 4,
        },
      },
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  if (cards.length === 0) { return (null) }

  return (
    <>
      <div className="navigation-wrapper">

        <h2 className="Application__title slider_title"> Ultimi <a href={`/prodotti?category=${id}`}> <img src={ require(`../../../assets/images/svg/homepage/${icon}`) } /></a> arrivi</h2>

        <div ref={sliderRef} className="keen-slider">
          {cards.map(({ id, name, slug, image }) => {
            return (
              <div key={id} className="keen-slider__slide">
                <SliderCard {...{ name, slug, image }} />
              </div>
            )
          })}
        </div>
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            )
          })}
        </div>
      )}
    </>
  )
}

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  )
}
