import React from 'react';
import CurrencyFormat from 'react-currency-format';

const Currency = ({ value }) => (
  <CurrencyFormat
    value={parseFloat(value)}
    displayType={'text'}
    thousandSeparator={true}
    decimalScale={2}
    fixedDecimalScale={true}
    decimalSeparator={','}
    thousandSeparator={'.'}
    suffix={' €'}
  />
);

export default Currency;
