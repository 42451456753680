import React, { Component, Fragment } from 'react';
import cx from 'classnames';

export default class Item extends Component {

  toggle = (e, id) => {

    if (this.props.currentCategory === id) {
      this.props.closeCategory(e)
    } else {
      this.props.showCategory(e, id)
    }

  }

  render() {

    const { id, text, currentCategory } = this.props;

    return (
      <li
        className={cx("Menu__item Menu__item--first", { "Menu__item--selected": currentCategory === id })}
        onClick={(e) => this.toggle(e, id)}
      >
        <a>
          <span className="Menu__itemText">
            {text}
          </span>
        </a>
      </li>
    );

  }

}
