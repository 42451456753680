import React, { Component } from "react";

export default class LoadingIndicator extends Component {

  render() {
    return (
      <div className="LoadingIndicator__wrapper">
        <div className="LoadingIndicator__content">
        </div>
      </div>
    );
  }
}

export const WithLoading = ({ isLoading, children, ...props }) => (
  isLoading ? <LoadingIndicator {...props} /> : children
);
