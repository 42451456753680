import React, { Component, Fragment } from 'react';

const Payment = ({ cart: { payment_id, delivery_category }, payments, onChange }) => (
  <div className="Application__box Application__box--payment">
    <div className="Application__title Application__title--flex">METODO DI PAGAMENTO</div>
    <div className="Application__subtitle">Pagamento online o direttamente in negozio, scegli tu.</div>
    <div className="Application__separator"></div>
    {payments.map(p => {

      return (
        <Fragment key={p.id}>
          <div className="Cart__payment">
            <span className="radio" onClick={() => onChange(p.id)}>
              <input type="radio" checked={p.id === payment_id} />
              <label className="radio-label" >
                {p.name}
              </label>
            </span>
            <span>
            </span>
          </div>
          <div className="Application__subtitle">
            {p.description}
          </div>
        </Fragment>
      );

    })}

    <div className="Application__subtitle" style={{ position: 'absolute', bottom: 0 }}>
      Per maggiori informazioni vedi <a href="/pagamenti">METODI DI PAGAMENTO</a>
    </div>

  </div>
);

export default Payment
