import React, { Component } from "react";
import ReactDOM from 'react-dom';

export default class Overlay extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };

    this.el = this.createContainer();

  }

  componentDidMount() {
    document.body.classList.add("noScroll");
  }

  componentWillUnmount() {
    document.body.classList.remove("noScroll");
  }

  render() {

    const content = (
      <div className="InstantSearch__overlay">
        {this.props.children}
      </div>
    );

    return ReactDOM.createPortal(content, this.el);

  }

  createContainer = () => {

    let container = document.getElementById("OverlayContainer");

    if (!container) {
      let opc = document.createElement("div");

      opc.id = "OverlayContainer";

      container = document.getElementsByTagName("body")[0].appendChild(opc);
    }

    return container;

  };

}
