import React, { Component, Fragment } from 'react';
import cx from 'classnames';

import times from 'lodash.times';

import Currency from './currency'
import Icon from './../Icon';

import Select from 'react-select';

export default class Edit extends Component {

  render() {

    const { loading, cart: { items, sub_total }, toggleEdit, updateItem, deleteItem } = this.props;

    const options = times(50, (n) => ({ value: n+1, label: `${n+1}` }));

    return (
      <div className="Application__box">

        <div className="Application__title">IL MIO CARRELLO</div>

        {items.map(({ id, name, quantity, price, product }) => (
          <Fragment key={id}>
            <div className="Application__separator"></div>

            <div className="Cart__item">

              <div className="Cart__itemImage">
                <img src={product.image} width={100} />
              </div>

              <div className="Cart__itemBlock">

                <div className="Cart__itemPrice">

                  <Currency value={price} />

                  <div className="Cart__itemRemove" onClick={() => deleteItem(id)}>
                    <Icon.Remove width={20} />
                  </div>

                </div>

                <div className="Cart__itemName">
                  {name}
                  <br/>
                  {product.status}
                </div>

                <label>Quantit&agrave;</label>

                <Select
                  className="Cart__itemQtSelect"
                  value={{ value: quantity, label: `${quantity}` }}
                  onChange={(val) => updateItem(id, val)}
                  options={product.pre_owned ? ([{ value: 1, label: '1' }]) : options}
                />

                <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: product.availability }} />

              </div>

            </div>
          </Fragment>
        ))}

        <div className="Cart__subtotal">
          <span>Subtotale</span>
          <span>
            <Currency value={sub_total} />
          </span>
        </div>

        <div
          onClick={toggleEdit}
          className={cx('btn', { 'btn--disabled': loading })}
          style={{ color: "#FFF" }}
        >
          Procedi con l'acquisto
        </div>

      </div>
    )
  }
};
