import React, { Component, Fragment } from 'react';
import Media from "react-media";
import { MeiliSearch } from 'meilisearch'

import Input from './Input';
import Overlay from './Overlay';
import Search from './../Search';

import Icon from './../Icon';

export default class InstantSearch extends Component {

  constructor(props) {

    super(props);

    this.state = this.initialState();

    this.client = new MeiliSearch({
      host: this.props.search_keys.id,
      apiKey: this.props.search_keys.key,
    })

    this.index = this.client.index(this.props.search_keys.index);

  }

  initialState() {
    return ({
      isOpen: false,
      query: '',
      loading: true,
      data: [],
    });
  }

  changeQuery = (event) => {

    let query = event.target.value;

    this.setState({
      query: query,
      loading: true
    }, () => { if (query.trim().length !== 0){ this.performSearch(); } });

  }

  performSearch = () => {

    const { query = "" } = this.state;

    this.index.search(query, { limit: 10, offset: 0 })
      .then(({ hits }) => {
        this.setState({
          loading: false,
          data: hits,
        });
      })
      .catch(err => {
        console.error(err);
      });

  }

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }))

  render() {

    return (
      <div id="ApplicationHeader">

        <Media query="(min-width: 800px)">

          <Search index={this.index} />

        </Media>

        <div className="Header__buttons">

          <Media query="(max-width: 799px)">
            <a className="Header__button" onClick={this.toggle}>
              <Icon.Lens />
            </a>
          </Media>

          <a href="/my-quinta" className="Header__button">
            <Icon.Avatar />
          </a>

          <a href="/carrello" className="Header__button">
            <Icon.Cart />
            <div className="Header__cartBadge" data-target="page.cartItems">
              {this.props.cartCount}
            </div>
          </a>

        </div>

        {this.state.isOpen && (
          <Overlay>

            <Input
              placeholder="Cerca un gioco"
              change={this.changeQuery}
              data={this.state.data}
              loading={this.state.loading}
              query={this.state.query}
            />

            <div className="InstantSearch__close" onClick={this.toggle}>
              <Icon.Close />
            </div>

          </Overlay>
        )}

      </div>
    );

  }

}
