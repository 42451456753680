import "keen-slider/keen-slider.scss"

import 'purecss/build/base'
import 'purecss/build/forms'

import 'cookieconsent/build/cookieconsent.min.css'

import ReactRailsUJS from "react_ujs";
import Rails from "rails-ujs";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import setupCSRFToken from './setupCSRFToken';
import 'bootstrap';

import cookieconsent from 'cookieconsent';

document.addEventListener("DOMContentLoaded", function() {

  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#eaf7f7",
        "text": "#5c7291"
      },
      "button": {
        "background": "#2ca3b6",
        "text": "#ffffff"
      }
    },
    "theme": "classic",
    "position": "bottom-left",
    content: {
      header: 'Questo sito utilizza cookie!',
      message: 'Utilizziamo i cookie per fornirti un esperienza ottimizzata.',
      dismiss: 'Sono d\'accordo',
      allow: 'Permetti Cookie',
      deny: 'Rifiuta',
      link: 'Maggiori informazioni',
      href: "https://quintadimensione.it/privacy",
      close: '&#x274c;',
      policy: 'Cookie Policy',
      target: '_blank',
    }
  });

});

setupCSRFToken();

const application = Application.start();

const context = require.context("controllers", true, /.js$/);

application.load(definitionsFromContext(context));

Rails.start();

var componentRequireContext = require.context("components", true);

ReactRailsUJS.useContext(componentRequireContext);
