import React from 'react';
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

const SocialShare = ({ shareUrl, title }) => {

  return (
    <div className="SocialShare">

      <FacebookShareButton
        url={shareUrl}
        quote={title}
      >
        <FacebookIcon
          size={32}
          round />
      </FacebookShareButton>

      <WhatsappShareButton
        url={shareUrl}
        title={title}
        separator=":: "
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <TwitterShareButton
        url={shareUrl}
        title={title}
      >
        <TwitterIcon
          size={32}
          round />
      </TwitterShareButton>

      <TelegramShareButton
        url={shareUrl}
        title={title}
        windowWidth={750}
        windowHeight={600}
      >
        <TelegramIcon
          size={32}
          round />
      </TelegramShareButton>

    </div>
  );

}

export default SocialShare;
