import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export default class Toaster extends Component {

  componentDidMount() {

    if (this.props.data.alert) {
      toast(this.props.data.alert)
    }

    if (this.props.data.notice) {
      toast(this.props.data.notice)
    }

  }

  render(){

    return (
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
      />
    );
  }
}
